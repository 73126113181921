.works {
  width: 100vw;
  background-color: black;
  color: white;
  font-family: "Dm Sans";
  padding-top: calc(100px + 9vmin);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10vh;
  min-height: 60vh;
}

.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.category-projects {
  display: grid;
  column-gap: 5%;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 4vw;
}

.category-name {
  font-size: 24px;
  width: 100%;
  text-align: end;
  cursor: pointer;
}

.category-name:hover {
  text-decoration: underline;
}

.project-thumb {
  max-width: 100%;
  cursor: pointer;
  filter: grayscale(1) contrast(1.2);
  transition: all 3s ease;
}
.project-thumb:hover {
  filter: none;
  /* box-shadow: 0 0 20px 10px white;  */ /* todo: grainy shadow*/
  transition-duration: 500ms;
}

@media (max-aspect-ratio: 1/1) and (max-width: 980px) {
  .category-projects {
    grid-template-columns: repeat(3, 1fr);
  }
}
