.project-container {
  margin: 0;
  height: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: calc(100px + 11vmin);
  font-family: "DM Sans";
}

.project-details {
  color: white;
  display: flex;
  flex-direction: row;
  width: 70vw;
  padding-block: 11rem;
}

.project-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}

.project-name .p {
  margin: 0px;
}

.team-info {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 40%;
  font-size: calc(10px + 0.8vmin);
}

.video-responsive {
  padding-bottom: 12vmin;
  overflow: hidden;
  /* padding: 56.25% 0 0 0; */
  position: relative;
  min-width: 50vw;
  width: 100%;
  height: 80vmin;
}

.responsive {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.project-name {
  font-size: calc(10px + 7.5vmin);
  pointer-events: none;
  /* width: 50vw; */
}

.project-description {
  text-align: left;
  font-size: calc(10px + 1.5vmin);
  word-wrap: break-word;
  width: 80%;
}

.project-team {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: 50vw;
  max-width: 90vw;
  font-size: calc(10px + 0.5vmin);
  color: white;
  margin: 2em;
  /* word-wrap: break-word; */
}

.project-role {
  margin: 1em;
  max-width: min-content;
  word-wrap: break-word;
}

@media (max-aspect-ratio: 1/1) and (max-width: 980px) {
  .project-details {
    flex-direction: column;
    width: 90vw;
    padding-block: 1rem;
  }

  .project-description {
    font-size: calc(10px + 1.7vmin);
    word-wrap: break-word;
    width: 80%;
  }

  .project-info {
    width: 100%;
  }

  .team-info {
    margin-top: 3.5rem;
    width: 90vw;
    justify-content: left;
  }

  .team-resp {
    width: 50%;
  }

  .team-members {
    width: 50%;
  }
}

@media (max-aspect-ratio: 1/1) and (max-width: 480px) {
  .project-details {
    flex-direction: column;
    width: 90vw;
    padding-block: 1rem;
  }

  .project-description {
    font-size: calc(10px + 1.7vmin);
    word-wrap: break-word;
    width: 80%;
  }

  .project-info {
    width: 100%;
  }

  .team-info {
    margin-top: 3.5rem;
    width: 90vw;
    justify-content: left;
  }

  .team-resp {
    width: 50%;
  }

  .team-members {
    width: 50%;
  }
}
