.app-footer {
  font-size: calc(8px + 0.8vmin);
  background-color: white;
}

.footer-text {
  display: flex;
  align-items: center;
  color: #222222;
}

.footer-text .mail-link {
  color: #222222;
  text-decoration: none;
}
.sunflower-logo-sm {
  height: 8vmin;
  min-height: 30px;
  pointer-events: none;
  user-select: none;
  margin: auto 0 auto 0;
}

.footer-wide {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 20px 0 20px 0;
}

.footer-mobile {
  display: none;
}

@media (max-aspect-ratio: 1/1) and (max-width: 480px) {
  .footer-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 1rem 0 1rem 0;
  }

  .footer-text {
    flex-direction: column;
  }

  .footer-wide {
    display: none;
  }

  .footer-text .mail-link {
    padding-top: 10px;
  }
}
