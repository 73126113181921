.project {
  width: 100%;
  background-color: black;
  height: 50vh;
  color: white;
  position: relative;
  cursor: pointer;
  font-family: "Dm Sans";
}

.project:first-of-type {
  height: 80vh;
}

.video-full {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  vertical-align: baseline;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(1) contrast(1.2);
  transition: filter 3s ease;
}

.video-full-overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  vertical-align: baseline;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(1) contrast(1.2) blur(15px);
  transition: filter 3s ease;
  mix-blend-mode: overlay;
  opacity: 0.8;
}

.video-full-g-overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  vertical-align: baseline;
  width: 100%;
  height: 100%;
  object-fit: cover;
  mix-blend-mode: overlay;
  opacity: 0.8;
}

.video-full:hover {
  filter: none;
  transition-duration: 500ms;
}

.project-title {
  position: absolute;
  bottom: 8px;
  right: 16px;
  font-size: 24px;
  /* text-shadow: 0px 0px 10px white; */
  mix-blend-mode: difference;
  text-align: right;
}

.separator {
  height: 5px;
  width: 100%;
  background-color: black;
}

.overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay:hover + .video-full {
  filter: none;
  transition-duration: 500ms;
}
