.about {
  margin: 0;
  max-height: fit-content;
  background-color: black;
  display: flex;
  text-align: center;
  padding-top: calc(100px + 9vmin);
  flex-direction: column;
  font-family: "DM Sans";
  color: white;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-block: 5rem;
  user-select: none;
}

.c-first-element {
  width: 45%;
  text-align: start;
}

.c-second-element {
  width: 25%;
  text-align: start;
}

.primary-text {
  font-size: calc(12px + 2vmin);
}

.secondary-text {
  font-size: calc(12px + 0.5vmin);
}

.secondary-text .link {
  color: white;
  text-decoration: none;
}

.secondary-text .link:hover {
  text-decoration: underline;
}

.member-role {
  font-size: calc(12px + 0.5vmin);
  padding-inline: 20px;
}
.team-member {
  text-align: start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.member-name {
  font-size: calc(12px + 0.6vmin);
  margin-right: 20px;
}
.sunflower-text {
  height: 3vmin;
}
.banner {
  width: 100vw;
}
.banner-image {
  max-width: 100%;
  max-height: 100%;
  width: 100vw;
  bottom: 0;
  left: 0;
  margin: auto;
  overflow: auto;
  right: 0;
  top: 0;
  -o-object-fit: contain;
  object-fit: contain;
}

@media (max-aspect-ratio: 1/1) and (max-width: 980px) {
  .container {
    flex-direction: column;
    justify-content: right;
    margin-block: 5vmin;
    user-select: none;
    padding-inline: 5vw;
  }
  .c-first-element {
    width: 100%;
    text-align: start;
  }
  .c-second-element {
    width: 100%;
    text-align: start;
  }
  .team-member {
    text-align: start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 1rem;
  }
  .member-name {
    width: 60%;
  }
  .member-role {
    width: 40%;
    font-size: calc(12px + 0.5vmin);
  }
  .primary-text {
    max-width: 90%;
  }
  .secondary-text {
    max-width: 60%;
    font-size: calc(12px + 0.5vmin);
  }
  .sunflower-text {
    height: 5vmin;
  }

  .color-white {
    color: white;
  }
}
